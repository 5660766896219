import { useMemo, createContext, useState } from 'react';
import { Home } from './containers/Home/Home';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { Box, IconButton } from '@mui/material';
import Brightness4Icon from '@mui/icons-material/Brightness4Rounded';
import Brightness7Icon from '@mui/icons-material/Brightness7Rounded';
import { grey } from '@mui/material/colors';
import classes from './App.module.css';

const ColorModeContext = createContext({ toggleColorMode: () => {} });

const App = () => {
  const [mode, setMode] = useState('dark');

  const color = useMemo(
    () => ({
      toggleColorMode: () => {
        setMode((prevMode) => (prevMode === 'light' ? 'dark' : 'light'));
      },
    }),
    [],
  );

  const themeMode = useMemo(
    () =>
      createTheme({
        palette: {
          mode,
          primary: {
            main: '#2491ff'
          }
        },
      }),
    [mode],
  );

  return (
    <ColorModeContext.Provider value={color}>
      <ThemeProvider theme={themeMode}>
        <Box
          className={classes.AppContainer}
          sx={{
            height: '100vh',
            width: '100vw',
            backgroundColor: mode === 'light' ? grey[100] : grey[900],
            color: 'text.primary',
          }}
        >
          <Home />
          <IconButton
            sx={{ position: 'fixed', top: 12, right: 12 }}
            onClick={color.toggleColorMode}
            color="inherit"
          >
            {mode === 'dark' ? (
              <Brightness7Icon />
            ) : (
              <Brightness4Icon />
            )}
          </IconButton>
        </Box>
      </ThemeProvider>
    </ColorModeContext.Provider>
  );
};

export default App;
