import React from 'react';
import classes from './resume-page.module.css';
import { Box, Button, Typography } from '@mui/material';
import Resume from '../../components/resume/resume';
import Skill from '../../components/skill/skill';
import { jsPDF } from 'jspdf';
import VerticalTechSkill from '../../components/vertical-tech-skill/vertical-tech-skill';
import CloudDownloadRoundedIcon from '@mui/icons-material/CloudDownloadRounded';

const ResumePage = () => {
  const resume = {
    summary: (
      <React.Fragment>
        <p style={{ fontSize: 12 }}>
          With more than 6 years of experience in the Software Industry,
          specializing in Full Stack Web Development; working with various
          frameworks and technologies, like Ruby on Rails, EmberJS, PostgresQL,
          ReactJS. And I intend to keep on learning and expand my skillset by
          going the extra mile and exposing myself to new challenges that may go
          beyond my skills.
        </p>
        <p style={{ fontSize: 12 }}>
          My love for aesthetically pleasing visuals has translated into love
          for building new features that focus on the user experience and
          usability, as well as producing clean, elegant and maintainable code
          that lasts for years to come.
        </p>
        <p style={{ fontSize: 12 }}>
          My desire for constant learning makes me a very good listener and
          great team player, always with an open mind that welcomes constructive
          criticism, feedback and advice. As well as finding the best way to
          communicate my own ideas and creativity to overcome any challenge that
          the team may face.
        </p>
      </React.Fragment>
    ),
    bodySections: [
      {
        id: 1,
        sectionTitle: 'Education',
        sectionBody: (
          <div
            style={{
              paddingLeft: 16,
            }}
          >
            <h3 style={{ margin: 0, fontSize: 18 }}>
              B.S. Computer Science
            </h3>
            <h5
              style={{
                margin: 0,
                fontSize: 14,
                fontWeight: 400,
                fontStyle: 'italic',
              }}
            >
              Instituto Tecnológico y de Estudios Superiores de Monterrey
            </h5>
            <h5
              style={{
                margin: 0,
                fontSize: 14,
                fontWeight: 300,
                fontStyle: 'italic',
                color: 'grey',
              }}
            >
              Aug 2014 - Dec 2019
            </h5>
          </div>
        ),
      },
      {
        id: 2,
        sectionTitle: 'Work Experience',
        sectionBody: (
          <React.Fragment>
            <h2 style={{ paddingLeft: 16 }}>
              Frontend Engineer{' '}
              <span
                style={{ fontSize: 18, fontWeight: 300, fontStyle: 'italic' }}
              >
                at Tech Mahindra
              </span>
            </h2>
            <p
              style={{
                paddingLeft: 18,
                margin: 0,
                fontStyle: 'italic',
                fontWeight: 400,
              }}
            >
              Enterprise Data Connectivity and Collaboration Platform
            </p>
            <p
              style={{
                paddingLeft: 18,
                margin: 0,
                fontStyle: 'italic',
                fontWeight: 400,
              }}
            >
              Sep 2021 - present
            </p>
						<p
							style={{
								marginLeft: 24,
								marginTop: 6,
								fontSize: 12,
								fontWeight: 300,
								fontStyle: 'italic',
								marginBottom: 2,
							}}
						>
							Working <span style={{ fontWeight: 600 }}>React</span> along with <span style={{ fontWeight: 600 }}>ExpressJS</span> as the primary focus for the
							frontend and backend services respectively.
						</p>
            <h2 style={{ paddingLeft: 16 }}>
              Backend Engineer{' '}
              <span
                style={{ fontSize: 18, fontWeight: 300, fontStyle: 'italic' }}
              >
                at Svitla Systems
              </span>
            </h2>
            <p
              style={{
                paddingLeft: 18,
                margin: 0,
                fontStyle: 'italic',
                fontWeight: 400,
              }}
            >
              eLearning Enterprise Platform
            </p>
            <p
              style={{
                paddingLeft: 18,
                margin: 0,
                fontStyle: 'italic',
                fontWeight: 400,
              }}
            >
              Jan 2021 - present
            </p>
						{/* <p
							style={{
								marginLeft: 24,
								marginTop: 6,
								fontSize: 12,
								fontWeight: 300,
								fontStyle: 'italic',
								marginBottom: 2,
							}}
						>
							Using <span style={{ fontWeight: 600 }}>Ruby on Rails</span> with <span style={{ fontWeight: 600 }}>MySQL</span> as the primary focus for the
							backend and <span style={{ fontWeight: 600 }}>Postman</span> as our endpoint testing tool, we intend to
							make every new API as modular and optimized as possible,
							reducing the amount of database calls as much as possible and integrate 3rd party API and services.
						</p> */}
            <h2 style={{ paddingLeft: 16 }}>
              Full Stack Engineer{' '}
              <span
                style={{ fontSize: 18, fontWeight: 300, fontStyle: 'italic' }}
              >
                at PRYSMEX
              </span>
            </h2>
            <p
              style={{
                paddingLeft: 18,
                margin: 0,
                fontStyle: 'italic',
                fontWeight: 400,
              }}
            >
              Industrial Safety and Facility
            </p>
            <p
              style={{
                paddingLeft: 18,
                margin: 0,
                fontStyle: 'italic',
                fontWeight: 400,
              }}
            >
              Feb 2016 - Jan 2021
            </p>
            {/* <p
              style={{
                marginLeft: 24,
								marginBottom: 2,
								marginTop: 6,
                fontSize: 12,
                fontWeight: 300,
                fontStyle: 'italic',
              }}
            >
              <span style={{ fontWeight: 600 }}>Ruby on Rails</span> with <span style={{ fontWeight: 600 }}>PostgreSQL</span>, <span style={{ fontWeight: 600 }}>Ember.js</span> as the primary tech stack,
              I worked full-time as a Full Stack developer while on Prysmex, for
              the most part. After my 3rd year, I transitioned into a more
              Frontend focused engineer, working on everything regarding the
              frontend, from UI/UX design, to development of such features.
            </p> */}
          </React.Fragment>
        ),
      },
      {
        id: 3,
        sectionTitle: 'Projects',
        sectionBody: (
          <div style={{ paddingLeft: 18 }}>
            <h4 style={{ margin: 0 }}>
              CV Xpress : <span style={{ fontWeight: 300, fontStyle: 'italic', margin: 8, textDecoration: 'underline' }}>cvxpress.online</span>
            </h4>
						<h4 style={{ margin: 0 }}>(
              <span style={{ fontStyle: 'italic', color: '#2e89ff' }}>
                React
              </span>
							,{' '}
              <span style={{ fontStyle: 'italic', color: '#2e89ff' }}>
                Redux
              </span>
              ,{' '}
              <span style={{ fontStyle: 'italic', color: '#2e89ff' }}>
                Typescript
              </span>
              ,{' '}
              <span style={{ fontStyle: 'italic', color: '#2e89ff' }}>
                Firebase
              </span>
              )
						</h4>
            <p
              style={{
                margin: 0,
                marginBottom: 3,
                fontSize: 12,
                fontWeight: 300,
                fontStyle: 'italic',
              }}
            >
              Online web app to build a CV in a matter of minutes, using
              React and Firebase.
            </p>
            <h4 style={{ margin: 0 }}>
              React PlayUI
            </h4>
						<h4 style={{ margin: 0 }}>(
              <span style={{ fontStyle: 'italic', color: '#2e89ff' }}>
                React
              </span>
							,{' '}
              <span style={{ fontStyle: 'italic', color: '#2e89ff' }}>
                Typescript
              </span>
              )
						</h4>
            <p
              style={{
                margin: 0,
                marginBottom: 3,
                fontSize: 12,
                fontWeight: 300,
                fontStyle: 'italic',
              }}
            >
              React Component Library focused on user interaction and ease of
              use, taking inspiration from Facebook v5.0 UI design, where
              everything looks and feels very clickable.
            </p>
            <h4 style={{ margin: 0 }}>
              Weather Now : <span style={{ fontWeight: 300, fontStyle: 'italic', margin: 8, textDecoration: 'underline' }}>https://weather-now-sigma.vercel.app</span>
            </h4>
            <h4 style={{ margin: 0 }}>(
              <span style={{ fontStyle: 'italic', color: '#2e89ff' }}>
                React
              </span>
							,{' '}
              <span style={{ fontStyle: 'italic', color: '#2e89ff' }}>
                Redux
              </span>
              ,{' '}
              <span style={{ fontStyle: 'italic', color: '#2e89ff' }}>
                Typescript
              </span>
              )
						</h4>
            <p
              style={{
                margin: 0,
                marginBottom: 3,
                fontSize: 12,
                fontWeight: 300,
                fontStyle: 'italic',
              }}
            >
              Weather Forecast App. Details on the current weather displayed as draggable widgets.
							Open Weather API is used to get the forecast.
            </p>
            <h4 style={{ margin: 0 }}>
              Pokedex : <span style={{ fontWeight: 300, fontStyle: 'italic', margin: 8, textDecoration: 'underline' }}>https://pokedex-ui.vercel.app</span>
            </h4>
						<h4 style={{ margin: 0 }}>(
              <span style={{ fontStyle: 'italic', color: '#2e89ff' }}>
                React
              </span>
							,{' '}
              <span style={{ fontStyle: 'italic', color: '#2e89ff' }}>
                Typescript
              </span>
							,{' '}
              <span style={{ fontStyle: 'italic', color: '#2e89ff' }}>
                Rails
              </span>
              )
						</h4>
            <p
              style={{
                margin: 0,
                marginBottom: 3,
                fontSize: 12,
                fontWeight: 300,
                fontStyle: 'italic',
              }}
            >
              Simple Pokedex UI made on React listing all pokemons on a paginated list. Rails is used as a backend service to fetch and serialize the Pokemon data from PokeAPI.
            </p>
          </div>
        ),
      },
    ],
    sidebarSections: [
      {
        id: 1,
        sectionBody: (
          <React.Fragment>
            <h2 style={{ fontSize: '1.5rem' }}>David Martinez de Silva</h2>
            <h3 style={{ margin: 0, fontStyle: 'italic' }}>Sr. Software Engineer</h3>
          </React.Fragment>
        ),
      },
      {
        id: 2,
        sectionTitle: 'Contact Data',
        sectionBody: (
          <React.Fragment>
            <h5 style={{ marginBottom: -4 }}>Location</h5>
            <p>Monterrey, Nuevo León, Mexico</p>
            <h5 style={{ marginBottom: -4 }}>Email</h5>
            <p>dmtzds@gmail.com</p>
            <h5 style={{ marginBottom: -4 }}>LinkedIn</h5>
            <p>
              <a href="https://linkedin.com/in/dmtzds">
                linkedin.com/in/dmtzds
              </a>
            </p>
            <h5 style={{ marginBottom: -4 }}>Github</h5>
            <p>
              <a href="https://github.com/SilvaMtz">github.com/SilvaMtz</a>
            </p>
            <h5 style={{ marginBottom: -4 }}>Website</h5>
            <p>
              <a href="https://www.davidmartinezdesilva.com">
                www.davidmartinezdesilva.com
              </a>
            </p>
          </React.Fragment>
        ),
      },
      {
        id: 3,
        sectionTitle: 'Stack',
        sectionBody: (
          <React.Fragment>
            <h3
              style={{
                margin: 0,
                padding: 8,
                paddingBottom: 2,
                boxSizing: 'border-box',
                textAlign: 'center',
              }}
            >
              Core
            </h3>
            <div
              style={{
                marginBottom: 6,
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between',
                height: '250px',
              }}
            >
              <VerticalTechSkill level={9} label="JS" tech="javascript" />
              <VerticalTechSkill level={8} tech="rails" />
              <VerticalTechSkill level={8} tech="react" />
              <VerticalTechSkill level={7} label="TS" tech="typescript" />
            </div>
            <h3
              style={{
                fontWeight: 500,
                margin: 0,
                padding: 8,
                paddingBottom: 2,
                boxSizing: 'border-box',
                textAlign: 'center',
              }}
            >
              Additional
            </h3>
            <Skill skillName="HTML / CSS" level="10" />
            <Skill skillName="PostgreSQL" level="7" />
            <Skill skillName="Ember.js" level="7" />
          </React.Fragment>
        ),
      },
      {
        id: 4,
        sectionTitle: 'Languages',
        sectionBody: (
          <React.Fragment>
            <Skill skillName="Spanish" level="10" levelName="Native" />
            <Skill skillName="English" level="10" levelName="Fluent" />
          </React.Fragment>
        ),
      },
    ],
  };

  const doc = new jsPDF({
    unit: 'px',
    hotfixes: ['px_scaling'],
    putOnlyUsedFonts: true,
    format: [860, 1216],
  });
  doc.scale(0.5);
  const downloadPdf = (divId, title) => {
    doc.html(document.getElementById(divId).innerHTML, {
      callback: function (doc) {
        doc.deletePage(2);
        doc.save(`${title}.pdf`);
      },
      margin: [0, 0, 0, 0],
      filename: `${title}.pdf`,
    });
    console.log(`${title}.pdf`);
  };

  return (
    <React.Fragment>
      <br />
      <div className={classes['mobile-message']}>
        <Typography variant="caption">To view the Online Resume, please use a Desktop browser.</Typography>
        <Typography variant="caption">or</Typography>
      </div>
      <Box sx={{ display: 'flex', justifyContent: 'center' }}>
        <Button
          size="small"
          variant="contained"
          disableElevation
          color='warning'
          onClick={() => downloadPdf('printable', 'David_Martinez_RESUME')}
          startIcon={<CloudDownloadRoundedIcon />}
        >
          Download CV
        </Button>
      </Box>
      <div id="online" className={[classes['resume-container'], classes['online']].join(' ')}>
        <Resume
          sidebarSections={resume.sidebarSections}
          summary={resume.summary}
          bodySections={resume.bodySections}
        />
      </div>
      <div id="printable" className={[classes['resume-container'], classes['printable']].join(' ')}>
        <Resume
          sidebarSections={resume.sidebarSections}
          summary={resume.summary}
          bodySections={resume.bodySections}
        />
      </div>
    </React.Fragment>
  );
};

export default ResumePage;
