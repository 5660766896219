import reactLogo from './assets/icons/react.png';
import emberLogo from './assets/icons/ember.png';
import cppLogo from './assets/icons/cpp.png';
import firebaseLogo from './assets/icons/firebase.png';
import nodeLogo from './assets/icons/node.png';
import htmlLogo from './assets/icons/html.png';
import pythonLogo from './assets/icons/python.png';
import railsLogo from './assets/icons/rails.png';
import rubyLogo from './assets/icons/ruby.png'
import pgLogo from  './assets/icons/postgres.png'
import jsLogo from './assets/icons/js.png';
import cssLogo from './assets/icons/css.png';
import blockchainLogo from './assets/icons/blockchain.png';
import typescriptLogo from './assets/icons/typescript.png';
import javaLogo from './assets/icons/java.png';
import springLogo from './assets/icons/spring.png';

export const TECH_ICONS = {
  REACT: reactLogo,
  EMBER: emberLogo,
  CPP: cppLogo,
  FIREBASE: firebaseLogo,
  NODE: nodeLogo,
  PYTHON: pythonLogo,
  RAILS: railsLogo,
  HTML: htmlLogo,
  RUBY: rubyLogo,
  POSTGRES: pgLogo,
  JAVASCRIPT: jsLogo,
  CSS: cssLogo,
  BLOCKCHAIN: blockchainLogo,
  TYPESCRIPT: typescriptLogo,
  JAVA: javaLogo,
  SPRING: springLogo
};
