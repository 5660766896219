import React from 'react';
import classes from './Skillbar.module.css';
import { Box } from '@mui/material';

const Skillbar = (props) => {
  return (
    <Box
      className={classes.SkillbarOuter}
      sx={{
        borderColor: 'text.secondary',
        borderWidth: '2px',
        borderStyle: 'solid',
      }}
    >
      <Box
        className={classes.SkillbarInner}
        sx={{ width: `${props.level}%` }}
      />
    </Box>
  );
};

export default Skillbar;
