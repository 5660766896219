import * as React from 'react';
import { styled } from '@mui/material/styles';
import { Global } from '@emotion/react';
import CssBaseline from '@mui/material/CssBaseline';
import { grey } from '@mui/material/colors';
import Box from '@mui/material/Box';
import SwipeableDrawer from '@mui/material/SwipeableDrawer';
import { IconButton, Grid, Button, useTheme, useMediaQuery } from '@mui/material';
import { CloseRounded } from '@mui/icons-material';

const drawerBleeding = 56;

const Puller = styled(Box)(({ theme }) => ({
  width: 30,
  height: 6,
  backgroundColor: theme.palette.mode === 'light' ? grey[300] : grey[900],
  borderRadius: 3,
  position: 'absolute',
  top: 8,
  left: 'calc(50% - 15px)',
}));

const InfoDrawer = (props) => {
  const theme = useTheme();

  const isMobile = useMediaQuery('(max-width: 600px)');

  return (
    <React.Fragment>
      <CssBaseline />
      <Global
        styles={{
          '.MuiDrawer-root > .MuiPaper-root': {
            height: isMobile ? `calc(100% - ${drawerBleeding + 200}px)` : `calc(70% - ${drawerBleeding}px)`,
            overflow: 'visible',
            left: isMobile ? 0 : `calc(50% - ${props.contentTitle === 'resume' ? 450 : 385}px)`,
            right: isMobile ? 0 : null,
            // right: window.innerWidth < 600 ? 0 : 'calc(20% - 65px)',
            maxWidth: `${props.contentTitle === 'resume' ? 900 : 770}px`,
            paddingTop: '12px',
            transition: 'transform 225ms cubic-bezier(0, 0, 0.2, 1) 0ms, max-width 0.1s linear, left 0.1s linear !important'
          },
        }}
      />
      <SwipeableDrawer
        anchor="bottom"
        hideBackdrop
        open={props.open}
        onClose={props.onClose}
        onOpen={props.onOpen}
        swipeAreaWidth={drawerBleeding}
        disableSwipeToOpen={false}
        ModalProps={{
          keepMounted: true,
        }}
      >
        <Box
          sx={{
            position: 'absolute',
            top: -drawerBleeding,
            borderTopLeftRadius: 24,
            borderTopRightRadius: 24,
            visibility: 'visible',
            right: '0',
            left: '0',
            backgroundColor:
              theme.palette.mode === 'light' ? '#fff' : '#353535',
            transition: 'background-color 0.1s linear',
          }}
        >
          <Puller />
          <Grid
            sx={{
              marginTop: '22px',
            }}
            container
            spacing={0}
          >
            <Grid item xs={12}>
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'center'
                }}
              >
                {props.open ? (
                  <React.Fragment>
                    <Button
                      variant={
                        props.contentTitle === 'about'
                          ? 'contained'
                          : 'outlined'
                      }
                      disableElevation
                      sx={{
                        borderTopRightRadius: 0,
                        borderBottomRightRadius: 0,
                        borderTopLeftRadius: 8,
                        borderBottomLeftRadius: 8,
                        fontWeight: 900
                      }}
                      onClick={() => props.onContentChange('about')}
                    >
                      About Me
                    </Button>
                    <Button
                      variant={
                        props.contentTitle === 'resume'
                          ? 'contained'
                          : 'outlined'
                      }
                      disableElevation
                      sx={{
                        borderRadius: 0,
                        borderLeft: 'none',
                        borderRight: 'none',
                        "&:hover": {
                          borderLeft: 'none',
                          borderRight: 'none',
                        },
                        fontWeight: 900
                      }}
                      onClick={() => props.onContentChange('resume')}
                    >
                      Resume
                    </Button>
                    <Button
                      variant={
                        props.contentTitle === 'projects'
                          ? 'contained'
                          : 'outlined'
                      }
                      sx={{
                        borderTopLeftRadius: 0,
                        borderBottomLeftRadius: 0,
                        borderTopRightRadius: 8,
                        borderBottomRightRadius: 8,
                        fontWeight: 900
                      }}
                      disableElevation
                      onClick={() => props.onContentChange('projects')}
                    >
                      Projects
                    </Button>
                  </React.Fragment>
                ) : (
                  <Box sx={{ height: '40px' }} />
                )}
              </Box>
            </Grid>
            {props.open && window.innerWidth > 600 ? (
              <IconButton
                sx={{
                  position: 'absolute',
                  top: '12px',
                  right: '12px',
                  zIndex: 9999,
                }}
                onClick={props.onClose}
              >
                <CloseRounded />
              </IconButton>
            ) : null}
          </Grid>
        </Box>
        <Box
          sx={{
            px: 4,
            pb: 4,
            height: '100%',
            overflow: 'auto',
            position: 'relative',
          }}
        >
          {props.content}
        </Box>
      </SwipeableDrawer>
    </React.Fragment>
  );
};

export default InfoDrawer;
