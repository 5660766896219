import React from 'react';
import classes from './skillbar.module.css';

const Skillbar = (props) => {
  let skillbar = <div className={classes['skillbar']} />;
  if (props.level <= 0) {
    skillbar = <div className={classes['skillbar-negative']} />;
  } else if (props.level > 0 && props.level < 10) {
    let whitebarWidth = (100 / 10) * props.level;
    let bluebarWidth = 100 - whitebarWidth;
    skillbar = (
      <React.Fragment>
        <div
          className={classes['skillbar']}
          style={{ width: `${whitebarWidth}%`,
					borderTopRightRadius: 0,
					borderBottomRightRadius: 0}}
        />
        <div
          className={classes['skillbar-negative']}
          style={{ width: `${bluebarWidth}%` }}
        />
      </React.Fragment>
    );
  }

  return <div className={classes['skillbar-container']}>{skillbar}</div>;
};

export default Skillbar;
