import React from 'react';
import classes from './resume-sidebar-section.module.css';

const ResumeSidebarSection = (props) => {
  let sectionTitle = props.sectionTitle ? (
    <h2 className={classes['sidebar-section-title']}>
      {props.sectionTitle}
    </h2>
  ) : null;

  return (
    <div className={classes['sidebar-section']}>
      {sectionTitle}
      <div className={classes['sidebar-section-body']}>
        {props.children}
      </div>
    </div>
  );
};

export default ResumeSidebarSection;
