import * as React from 'react';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import TechIcon from '../tech-icon/tech-icon';
import { grey } from '@mui/material/colors';
import { useMediaQuery, useTheme } from '@mui/material';

export function ProjectCard(props) {
  const { image, title, href, stack, description } = props;
  const theme = useTheme();

  const isMobile = useMediaQuery('(min-width: 600px)');

  const openUrl = (url) => {
    window.open(url, '_blank');
  }

  return (
    <Card
      elevation={false}
      onClick={() => openUrl(href)}
      sx={{
        display: 'flex',
        cursor: 'pointer',
        transition: 'border 0.2s linear',
        borderRadius: '12px',
        borderStyle: 'solid',
        borderWidth: '1px',
        borderColor: theme.palette.mode === 'light' ? grey[300] : grey[600],
        backgroundColor: theme.palette.mode === 'dark' ? grey[800] : null,
        ':hover': { borderColor: 'primary.main', borderWidth: '2px' },
        marginTop: '12px'
      }}
    >
      <CardMedia
        component="img"
        sx={{ width: 151, display: !isMobile ? 'none' : null }}
        image={image}
        alt={title}
      />
      <Box sx={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
        <CardContent sx={{ flex: '1 0 auto' }}>
          <Typography component="div" variant="h5">
            {title}
          </Typography>
          <Typography
            variant="caption"
            color="text.secondary"
            sx={{ fontStyle: 'italic' }}
            component="div"
          >
            {description}
          </Typography>
        </CardContent>
        <Box sx={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center', pl: 1, pb: 1 }}>
          {stack.map((t) => (
            <TechIcon tech={t} />
          ))}
        </Box>
      </Box>
    </Card>
  );
}
