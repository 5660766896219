import React from 'react';
import Skillbar from './Skillbar/Skillbar';
import classes from './TechSkill.module.css'
import TechIcon from '../tech-icon/tech-icon';
import { Typography, Box } from '@mui/material';

const Skill = (props) => {
  return (
    <Box
      className={classes.TechSkill}
      sx={{
        width: '100%',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
      }}
    >
      <TechIcon tech={props.skill} />
      <Box
        sx={{
          width: '100%',
        }}
      >
        <Typography sx={{ my: '0px' }} variant="caption">
          {props.skillName}
        </Typography>
        <Skillbar level={props.level} />
      </Box>
    </Box>
  );
};

export default Skill;
