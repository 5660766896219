import React from 'react';
import classes from './resume-sidebar.module.css';

const ResumeSidebar = (props) => {

  return (
    <div className={classes['sidebar']}>
      {props.children}
    </div>
  );
}

export default ResumeSidebar;