import React from 'react';
import classes from './skill.module.css';
import Skillbar from './skillbar/skillbar';

const Skill = (props) => {
  const skillLevelMapping = {
    0: 'None',
    1: 'Beginner',
    2: 'Basic',
    3: 'Intermediate',
    4: 'Advanced',
    5: 'Expert',
  };

  let levelTag = skillLevelMapping[Math.floor(props.level / 2)];
  if (props.levelName) {
    levelTag = props.levelName;
  }

  return (
    <div className={classes['skill']}>
      <p style={{ fontWeight: props.boldLabel ? 'bold' : 400, marginBottom: 2 }}>{props.skillName}</p>
      <Skillbar level={props.level} />
      <h5 className={classes['skill-level']}>
        {levelTag}
      </h5>
    </div>
  );
};

export default Skill;
