import React from 'react';
import classes from './ProfileDisplay.module.css';
import profile from '../../assets/images/profile-web-1.jpg';

export const ProfileDisplay = () => {

  return (
    <div className={classes.ProfileDisplay}>
      <div className={classes.ImageContainer}>
        <img className={classes.ProfileImage} alt="david" src={profile} />
      </div>
      <br />
      <br />
    </div>
  );
}
