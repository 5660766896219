import React from 'react';
import classes from './resume-summary.module.css';

const ResumeSummary = (props) => {

  return (
    <div className={classes['resume-summary']}>
      {props.children}
    </div>
  );
}

export default ResumeSummary;