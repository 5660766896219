import React from 'react';
import classes from './vertical-tech-skill.module.css';
import TechIcon from '../tech-icon/tech-icon';

const VerticalTechSkill = (props) => {

	let skillbar = <div className={classes['skillbar']} />;
  if (props.level <= 0) {
    skillbar = <div className={classes['skillbar-negative']} />;
  } else if (props.level > 0 && props.level < 10) {
    let whitebarHeight = (100 / 10) * props.level;
    let bluebarHeight = 100 - whitebarHeight;
    skillbar = (
      <React.Fragment>
				<div
					className={classes['skillbar-negative']}
					style={{ height: `${bluebarHeight}%` }}
				/>
        <div
          className={classes['skillbar']}
          style={{ height: `${whitebarHeight}%`, borderTopLeftRadius: 0, borderTopRightRadius: 0 }}
        />
      </React.Fragment>
    );
  }

  const skillbarInstance = <div className={classes['skillbar-container']}>{skillbar}</div>;

  return (
    <div className={classes['skill']}>
      {skillbarInstance}
      <TechIcon tech={props.tech} />
			<h6 className={classes['Label']}>{props.label ? props.label : props.tech}</h6>
    </div>
  );
};

export default VerticalTechSkill;
