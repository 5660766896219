import React from 'react';
import classes from './resume-body.module.css';

const ResumeBody = (props) => {

  return (
    <div className={classes['resume-body']}>
      {props.children}
    </div>
  );
}

export default ResumeBody;