import React from 'react';
import { Typography, Box, Grid } from '@mui/material';
import HomeRepairServiceRoundedIcon from '@mui/icons-material/HomeRepairServiceRounded';
import FeedRoundedIcon from '@mui/icons-material/FeedRounded';
import TechSkill from '../../components/TechSkill/TechSkill';

export const About = () => {
  // const skillBlocks = [
  //   {
  //     id: 1,
  //     title: 'Core Languages',
  //     stack: ['javascript', 'ruby'],
  //     description: `
  //       These are the main programming languages
  //       I have been using professionally for the past 5+ years.
  //       `,
  //   },
  //   {
  //     id: 2,
  //     title: 'Proficient Languages',
  //     stack: ['cpp', 'python'],
  //     description: `
  //       Proficiency in both these languages has allowed me to fulfill
  //       everyday tasks and scripts. No professional experience in both of them.
  //       `,
  //   },
  //   {
  //     id: 3,
  //     title: 'Core Tech Stack',
  //     stack: ['ember', 'rails', 'postgres'],
  //     description: `
  //       I have been a Full Stack Engineer for the past 5+ years, starting with
  //       Ruby on Rails and PostgresQL, late working on different stacks with Ember.js on the
  //       frontend side.
  //       `,
  //   },
  //   {
  //     id: 4,
  //     title: 'Proficient Tech Stack',
  //     stack: ['node', 'react', 'firebase'],
  //     description: `
  //       Learning and studying new libraries and technologies, such as React, Node, and Firebase
  //       keep me motivated to expand my knowledge and skillset.
  //       `,
  //   },
  //   {
  //     id: 5,
  //     title: 'Web Dominance',
  //     stack: ['html', 'css', 'javascript'],
  //     description: `
  //       I love great visuals and beautiful UI/UX design.
  //       This has translated into mastery of HTML, CSS to craft pixel perfect UI and UX;
  //       `,
  //   },
  //   {
  //     id: 6,
  //     title: 'Eternal Student',
  //     stack: ['react', 'node', 'rails', 'ember'],
  //     description: `
  //       I am by no means a total expert and know-all in any of these
  //       technologies, but I always aim to expose myself to new challenges and grow
  //       as a professional
  //       `,
  //   },
  // ];

  return (
    <React.Fragment>
      <Typography
        sx={{ pl: 2, display: 'flex', alignItems: 'center' }}
        variant="h6"
      >
        <FeedRoundedIcon sx={{ mr: 1 }} /> Summary
      </Typography>
      <Box
        sx={{
          borderLeftColor: 'primary.main',
          borderLeftStyle: 'solid',
          borderLeftWidth: '5px',
          boxSizing: 'border-box',
          paddingLeft: '12px',
        }}
      >
        <Typography variant="subtitle2" sx={{ fontStyle: 'italic', py: '4px' }}>
          Hi! I'm David, Software Engineer with a Bachelor of Science in
          Computer Science. I specialize in Web Development across all stacks,
          from database design to UI design. I am a UI/UX enthusiast and have
          always aim to deliver beautiful looking web app designs. Also, I'm a
          Star Wars fanatic.
        </Typography>
      </Box>
      <br />
      <Typography
        sx={{ pl: 2, display: 'flex', alignItems: 'center' }}
        variant="h6"
      >
        <HomeRepairServiceRoundedIcon sx={{ mr: 1 }} /> My Toolkit
      </Typography>
      <Grid flexDirection="row" container spacing={2}>
        <Grid item xs={12} sm={6}>
          <Box
            sx={{
              p: 2
            }}
          >
            <Typography variant="subtitle1" sx={{ fontWeight: 900 }} align='center'>
              Core
            </Typography>
            <Box sx={{ width: '100%' }}>
              <TechSkill skill="react" skillName="React" level={85} />
              <TechSkill skill="typescript" skillName="Typescript" level={85} />
              <TechSkill skill="node" skillName="Node - ExpressJS" level={76} />
              <TechSkill skill="ruby" skillName="Ruby" level={90} />
              <TechSkill skill="rails" skillName="Ruby on Rails" level={92} />
              <TechSkill skill="postgres" skillName="PostgreSQL" level={80} />
            </Box>
          </Box>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Box
            sx={{
              p: 2
            }}
          >
            <Typography variant="subtitle1" sx={{ fontWeight: 900 }} align='center'>
              Experienced
            </Typography>
            <Box sx={{ width: '100%' }}>
              <TechSkill skill="ember" skillName="EmberJS" level={70} />
              <TechSkill skill="python" skillName="Python" level={55} />
              <TechSkill skill="cpp" skillName="C++" level={62} />
              <Typography sx={{ fontWeight: 900, marginTop: '35px' }} variant="subtitle2" align='center'>
                Now Learning
              </Typography>
              <TechSkill skill="java" skillName="Java" level={10} />
              <TechSkill skill="spring" skillName="Spring" level={10} />
            </Box>
          </Box>
        </Grid>
      </Grid>
    </React.Fragment>
  );
};
