import React from 'react';
import classes from './resume-body-section.module.css';

const ResumeBodySection = (props) => {

  return (
    <div className={classes['resume-body-section']}>
      <h1 style={{ fontSize: 18 }}>
        {props.sectionTitle}
      </h1>
      {props.children}
    </div>
  );
}

export default ResumeBodySection;