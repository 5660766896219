import React from 'react';
import classes from './resume.module.css';
import ResumeSidebar from './resume-sidebar/resume-sidebar';
import ResumeBody from './resume-body/resume-body';
import ResumeSummary from './resume-summary/resume-summary';
import ResumeBodySection from './resume-body-section/resume-body-section';
import ResumeSidebarSection from './resume-sidebar-section/resume-sidebar-section';


const Resume = (props) => {
  return (

    <div className={classes['resume']}>
      <ResumeSidebar>
        {props.sidebarSections.map(section => {
          return (
            <ResumeSidebarSection key={section.id} sectionTitle={section.sectionTitle}>
              {section.sectionBody}
            </ResumeSidebarSection>
          )
        })}
      </ResumeSidebar>
      <ResumeBody>
        <ResumeSummary>
          {props.summary}
        </ResumeSummary>
        {props.bodySections.map(section => {
          return (
            <ResumeBodySection key={section.id} sectionTitle={section.sectionTitle}>
              {section.sectionBody}
            </ResumeBodySection>
          )
        })}
      </ResumeBody>
    </div>
  )
}

export default Resume;