import React, { useState } from 'react';
import classes from './Home.module.css';
import { ProfileDisplay } from '../../components/ProfileDisplay/ProfileDisplay';
import { SocialIcons } from '../../components/ProfileDisplay/SocialIcons/SocialIcons';
import { Box, Button, Typography, useTheme } from '@mui/material';
import InfoDrawer from '../../components/InfoDrawer/InfoDrawer';
import { About } from '../About/About';
import ResumePage from '../resume-page/resume-page';
import { Projects } from '../Projects/Projects';
import { grey } from '@mui/material/colors';

export const Home = () => {
  const [open, setOpen] = useState(false);
  const theme = useTheme();
  const contentMap = {
    about: <About />,
    projects: <Projects />,
    resume: <ResumePage />,
  }
  const [content, setContent] = useState('about');

  const setDrawer = (value) => {
    setOpen(true);
    setContent(value)
  }

  return (
    <Box className={classes.Home}>
      <ProfileDisplay />
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          flexDirection: 'column',
          paddingTop: '24px',
          paddingBottom: '24px',
          borderBottomWidth: '1px',
          borderBottomStyle: 'solid',
          borderBottomColor: theme.palette.mode === 'light' ? grey[500] : grey[600],
          borderTopWidth: '1px',
          borderTopStyle: 'solid',
          borderTopColor: theme.palette.mode === 'light' ? grey[500] : grey[600],
          borderRadius: '8px'
        }}
      >
        <Typography
          sx={{ letterSpacing: '2px' }}
          variant="h5"
          component="div"
        >
          DAVID MARTINEZ DE SILVA
        </Typography>
        <br />
        <Typography
          variant="h6"
          sx={{ letterSpacing: '2px' }}
          component="div"
        >
          Sr. Software Engineer
        </Typography>
        <Typography
          variant="caption1"
          sx={{ letterSpacing: '2px', fontStyle: 'italic', color: 'text.primary', "& a:visited": { color: "text.primary" } }}
          component="div"
        >
          <a
            style={{ color: 'text.primary' }}
            href="https://www.linkedin.com/company/digitalonus/"
          >
            @ Tech Mahindra
          </a>
        </Typography>
      </Box>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          flexDirection: 'row',
          paddingTop: '40px',
          paddingBottom: '40px',
        }}
      >
        <Button
                      variant="contained"
                      disableElevation
                      sx={{
                        borderTopRightRadius: 0,
                        borderBottomRightRadius: 0,
                        borderTopLeftRadius: 8,
                        borderBottomLeftRadius: 8,
                        fontWeight: 900
                      }}
                      onClick={() => setDrawer('about')}
                    >
                      About Me
                    </Button>
                    <Button
                      variant="contained"
                      disableElevation
                      sx={{
                        borderRadius: 0,
                        borderLeft: 'none',
                        borderRight: 'none',
                        "&:hover": {
                          borderLeft: 'none',
                          borderRight: 'none',
                        },
                        fontWeight: 900
                      }}
                      onClick={() => setDrawer('resume')}
                    >
                      Resume
                    </Button>
                    <Button
                      variant="contained"
                      sx={{
                        borderTopLeftRadius: 0,
                        borderBottomLeftRadius: 0,
                        borderTopRightRadius: 8,
                        borderBottomRightRadius: 8,
                        fontWeight: 900
                      }}
                      disableElevation
                      onClick={() => setDrawer('projects')}
                    >
                      Projects
                    </Button>
      </Box>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          flexDirection: 'row',
        }}
      >
        <SocialIcons />
      </Box>
      <InfoDrawer
        open={open}
        onClose={() => setOpen(false)}
        onOpen={() => setOpen(true)}
        content={contentMap[content]}
        contentTitle={content}
        onContentChange={setContent}
      />
    </Box>
  );
};
