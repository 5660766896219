import { Grid } from '@mui/material';
import eui from '../../assets/images/ember-eui.jpg';
import foma from '../../assets/images/foma.jpg';
import playui from '../../assets/images/play-ui-logo.svg';
import pokedex from '../../assets/images/pokedex-logo.png';
import weather from '../../assets/images/weather.png';
import cvxpress from '../../assets/images/cvexpress.svg';
import { ProjectCard } from '../../components/ProjectCard/ProjectCard';

export function Projects() {
  const projects = [
    {
      id: 1,
      title: 'Simple Pokedex',
      description:
        'Simple Pokedex UI made on React listing all pokemons on a paginated list. Rails is used as a backend service to fetch and serialize the Pokemon data from PokeAPI.',
      image: pokedex,
      stack: ['react', 'typescript', 'rails', 'ruby'],
      href: 'https://pokedex-ui.vercel.app/'

    },
    {
      id: 2,
      title: 'Weather Now',
      description:
        'Weather Forecast App. Details on the current weather displayed as draggable widgets. Open Weather API is used to get the forecast.',
      image: weather,
      stack: ['react', 'typescript'],
      href: 'https://weather-now-sigma.vercel.app/'
    },
    {
      id: 3,
      title: 'React Play UI',
      description:
        'React Component Library meant for extreme interactive feel and a playful look. Built on Typescript.',
      image: playui,
      stack: ['react', 'typescript'],
      href: 'https://github.com/SilvaMtz/react-play-ui'
    },
    {
      id: 4,
      title: 'CV Xpress',
      description:
        'A web app created to help people build their professional-looking resume for free!',
      image: cvxpress,
      stack: ['react', 'typescript', 'firebase'],
      href: 'https://cvxpress.online/'
    },
    {
      id: 5,
      title: 'Ember Eui',
      description:
        'Core team member of an open source Ember.js Component library based on ElasticUI for React.',
      image: eui,
      stack: ['ember', 'react', 'typescript', 'javascript'],
      href: 'https://github.com/prysmex/ember-eui'
    },
    {
      id: 6,
      title: 'FOMA',
      description: 'OOP Language compiler built purely on Ruby.',
      image: foma,
      stack: ['ruby'],
      href: 'https://github.com/SilvaMtz/FOMA'
    },
  ];

  return (
    <Grid container flexDirection="column">
      {projects.map((p) => {
        return (
          <Grid key={p.id} item xs="12">
            <ProjectCard
              stack={p.stack}
              title={p.title}
              description={p.description}
              image={p.image}
              href={p.href}
            />
          </Grid>
        );
      })}
    </Grid>
  );
}
