import React from 'react';
import classes from './tech-icon.module.css';
import { TECH_ICONS } from '../../constants';
import ReactTooltip from 'react-tooltip';

const iconMapping = {
  ember: TECH_ICONS.EMBER,
  cpp: TECH_ICONS.CPP,
  firebase: TECH_ICONS.FIREBASE,
  html: TECH_ICONS.HTML,
  python: TECH_ICONS.PYTHON,
  rails: TECH_ICONS.RAILS,
  react: TECH_ICONS.REACT,
  node: TECH_ICONS.NODE,
  ruby: TECH_ICONS.RUBY,
  postgres: TECH_ICONS.POSTGRES,
  javascript: TECH_ICONS.JAVASCRIPT,
  css: TECH_ICONS.CSS,
  blockchain: TECH_ICONS.BLOCKCHAIN,
  typescript: TECH_ICONS.TYPESCRIPT,
  java: TECH_ICONS.JAVA,
  spring: TECH_ICONS.SPRING
};

const TechIcon = (props) => {
  return (
    <React.Fragment>
      <div className={classes['tech-icon']} data-tip data-for={props.tech}>
        <img src={iconMapping[props.tech]} alt={props.tech} />
      </div>
      {props.showTooltip ? (
        <ReactTooltip
          place={props.tooltipPosition ? props.tooltipPosition : 'bottom'}
          id={props.tech}
          type="info"
          effect="solid"
        >
          <p className={classes['tooltip-text']}>{props.tech}</p>
        </ReactTooltip>
      ) : null}
    </React.Fragment>
  );
};

export default TechIcon;
